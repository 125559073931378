import { FeatureItem } from "@type/feature/feature.types";
import { featureItems } from "@utils/enums/feature.items.enum";

export const MENU: FeatureItem[] = [
  featureItems.home,
  featureItems.users,
  featureItems.gazProfessionals,
  featureItems.organisationProfessional,
  featureItems.structure,
  featureItems.pages,
  featureItems.keywords,
  {
    ...featureItems.leads,
    children: [featureItems.leadsMap, featureItems.leadsEvent],
  },
  {
    ...featureItems.contacts,
    children: [
      featureItems.contactsIndividual,
      featureItems.contactsProfessional,
    ],
  },

  {
    ...featureItems.mentions,
    children: [
      featureItems.mentionsPacHybride,
      featureItems.mentionsGreenGaz,
      featureItems.mentionsSmokeDuct,
      featureItems.worksites,
    ],
  },
  {
    ...featureItems.events,
    children: [featureItems.webinar, featureItems.gazMonth],
  },
  featureItems.resources,
  featureItems.mail,
];
