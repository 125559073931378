import { FC } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { useParams } from "react-router-dom";
import ContactForm from "@views/contacts/ContactForm";

const ContactIndividualScreen: FC = () => {
  const { contactId } = useParams<{ contactId: string }>();

  const { t } = useTranslation();

  return (
    <PageLayout title={t<string>("contacts.contact.details.title")}>
      <ContactForm contactId={contactId ?? ""} particulier={true} />
    </PageLayout>
  );
};

export default ContactIndividualScreen;
