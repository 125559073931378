import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import DashboardScreen from "@views/home/DashboardScreen";
import "./styles/styles.scss";
import "./utils/i18n/i18n";
import LoginScreen from "@views/auth/LoginScreen";
import UsersScreen from "@views/users/list/UsersScreen";
import UserAddScreen from "@views/users/add/UserAddScreen";
import UserScreen from "@views/users/details/UserScreen";
import AppInfosRibbon from "@components/ribbons/AppInfosRibbon";
import ForgotPasswordScreen from "@views/auth/ForgotPasswordScreen";
import InitPasswordScreen from "@views/auth/InitPasswordScreen";
import RecoverPasswordScreen from "@views/auth/RecoverPasswordScreen";
import TasksScreen from "@views/tasks/TasksScreen";
import ComponentsScreen from "@views/components/ComponentsScreen";
import ToastAlert from "@components/alerts/ToastAlert";
import EndpointsScreen from "@views/endpoints/list/EndpointsScreen";
import GazProfessionalsScreen from "@views/gazProfessional/list/GazProfessionalsScreen";
import AddCategoryScreen from "@views/category/add/AddCategoryScreen";
import PagesScreen from "@views/page/list/PagesScreen";
import AddPageScreen from "@views/page/add/AddPageScreen";
import PageScreen from "@views/page/details/PageScreen";
import StructureScreen from "@views/structure/StructureScreen";
import GazProfessionalScreen from "@views/gazProfessional/details/GazProfessionalScreen";
import KeywordsScreen from "@views/keyword/list/KeywordsScreen";
import LeadsScreen from "@views/contacts/leads/LeadsScreen";
import ContactsScreen from "@views/contacts/ContactsScreen";
import ResourcesScreen from "@views/resource/ResourcesScreen";
import MentionsPacHybrideScreen from "@views/mention/pachybride/MentionsPacHybrideScreen";
import MentionPacHybrideScreen from "@views/mention/pachybride/MentionPacHybrideScreen";
import GazMonthScreen from "@views/gazMonth/GazMonthScreen";
import configuration from "@utils/Config";
import WebinarsScreen from "@views/webinar/WebinarsScreen";
import MentionsSmokeDuctScreen from "@views/mention/smokeduct/MentionsSmokeDuctScreen";
import MentionSmokeDuctScreen from "@views/mention/smokeduct/MentionSmokeDuctScreen";
import WorksitesScreen from "@views/worksite/WorksitesScreen";
import WorksiteScreen from "@views/worksite/WorksiteScreen";
import MailsScreen from "@views/mail/MailsScreen";
import ContactScreen from "@views/contacts/ContactScreen";
import LeadScreen from "@views/contacts/leads/LeadScreen";
import MentionsGreenGazScreen from "@views/mention/greenGaz/MentionsGreenGazScreen";
import { MyContextProvider } from "./MyContext";
import { ROUTES } from "@routes/Routes";
import AuthLayout from "@components/routes/AuthLayout";
import ProfileLayout from "@components/routes/ProfileLayout";
import { ProfileEnum } from "@utils/enums/profile.enum";
import OrganisationProfessionalScreen from "@views/organisationProfessional/details/OrganisationProfessionalScreen";
import OrganisationProfessionalsScreen from "@views/organisationProfessional/list/OganisationProfessionalsScreen";
import LeadsEventScreen from "@views/contacts/leadsEvent/LeadsEventScreen";
import SelectUserScreen from "@views/e2e/users/SelectUserScreen";
import ContactsIndividualScreen from "@views/contacts/ContactsIndividualScreen";
import ContactIndividualScreen from "@views/contacts/ContactIndividualScreen";

export const App: FC = () => {
  return (
    <div className="global">
      {configuration.environment !== "production" && <AppInfosRibbon />}
      <ToastAlert />

      <MyContextProvider>
        <Routes>
          <Route
            path={ROUTES.auth.login.generate()}
            element={<LoginScreen />}
          />
          <Route
            path={ROUTES.auth.forgotPassword.generate()}
            element={<ForgotPasswordScreen />}
          />
          <Route
            path={ROUTES.auth.initPassword.generate()}
            element={<InitPasswordScreen />}
          />
          <Route
            path={ROUTES.auth.recoverPassword.generate()}
            element={<RecoverPasswordScreen />}
          />
          <Route
            path={ROUTES.e2e.users.generate()}
            element={<SelectUserScreen />}
          />

          <Route element={<AuthLayout />}>
            <Route
              path={ROUTES.admin.home.generate()}
              element={<DashboardScreen />}
            />

            <Route
              element={<ProfileLayout allowedProfiles={[ProfileEnum.ADMIN]} />}
            >
              {/*Users*/}
              <Route
                path={ROUTES.admin.users.add.generate()}
                element={<UserAddScreen />}
              />
              <Route
                path={ROUTES.admin.users.details.generate(":userId")}
                element={<UserScreen />}
              />
              <Route
                path={ROUTES.admin.users.list.generate()}
                element={<UsersScreen />}
              />

              {/*Endpoints*/}
              <Route
                path={ROUTES.management.endpoints.generate()}
                element={<EndpointsScreen />}
              />
            </Route>

            <Route
              element={
                <ProfileLayout allowedProfiles={[ProfileEnum.SERVICES]} />
              }
            >
              {/*Leads*/}
              <Route
                path={ROUTES.leads.details.generate(":leadId")}
                element={<LeadScreen />}
              />
              <Route
                path={ROUTES.leads.list.generate()}
                element={<LeadsScreen />}
              />
              <Route
                path={ROUTES.leadsEvent.list.generate()}
                element={<LeadsEventScreen />}
              />

              {/*Contacts*/}
              <Route
                path={ROUTES.contacts.details.generate(":contactId")}
                element={<ContactScreen />}
              />
              <Route
                path={ROUTES.contacts.list.generate()}
                element={<ContactsScreen />}
              />
              <Route
                path={ROUTES.contacts.individual.details.generate(":contactId")}
                element={<ContactIndividualScreen />}
              />
              <Route
                path={ROUTES.contacts.individual.list.generate()}
                element={<ContactsIndividualScreen />}
              />

              {/*Tasks*/}
              <Route
                path={ROUTES.management.tasks.list.generate()}
                element={<TasksScreen />}
              />

              {/*Mails*/}
              <Route
                path={ROUTES.management.mails.generate()}
                element={<MailsScreen />}
              />

              <Route
                path={ROUTES.management.mails.generate()}
                element={<ComponentsScreen />}
              />

              {/*Gaz Professionals*/}
              <Route
                path={ROUTES.gazProfessionals.details.generate(":yoocanId")}
                element={<GazProfessionalScreen />}
              />
              <Route
                path={ROUTES.gazProfessionals.list.generate()}
                element={<GazProfessionalsScreen />}
              />

              {/*Organisation Professionals*/}
              <Route
                path={ROUTES.organisationProfessionals.details.generate(
                  ":yoocanId",
                )}
                element={<OrganisationProfessionalScreen />}
              />
              <Route
                path={ROUTES.organisationProfessionals.list.generate()}
                element={<OrganisationProfessionalsScreen />}
              />

              {/*Mentions PAC Hybride*/}
              <Route
                path={ROUTES.mentionsPacHybride.details.generate(
                  ":mentionPacHybrideId",
                )}
                element={<MentionPacHybrideScreen />}
              />
              <Route
                path={ROUTES.mentionsPacHybride.list.generate()}
                element={<MentionsPacHybrideScreen />}
              />

              {/*Mentions Smoke Duct*/}
              <Route
                path={ROUTES.mentionsSmokeDuct.details.generate(
                  ":mentionSmokeDuctId",
                )}
                element={<MentionSmokeDuctScreen />}
              />
              <Route
                path={ROUTES.mentionsSmokeDuct.list.generate()}
                element={<MentionsSmokeDuctScreen />}
              />

              {/*Mentions Green Gaz*/}
              <Route
                path={ROUTES.mentionsGreenGaz.list.generate()}
                element={<MentionsGreenGazScreen />}
              />

              {/*Worksites*/}
              <Route
                path={ROUTES.worksites.details.generate(":worksiteId")}
                element={<WorksiteScreen />}
              />
              <Route
                path={ROUTES.worksites.list.generate()}
                element={<WorksitesScreen />}
              />

              {/*Resources*/}
              <Route
                path={ROUTES.resources.list.generate()}
                element={<ResourcesScreen />}
              />

              {/*Gaz Prom*/}
              <Route
                path={ROUTES.gazProm.generate()}
                element={<GazMonthScreen />}
              />

              {/*Webinar*/}
              <Route
                path={ROUTES.webinar.generate()}
                element={<WebinarsScreen />}
              />
            </Route>

            <Route
              element={<ProfileLayout allowedProfiles={[ProfileEnum.CMS]} />}
            >
              {/*Categories*/}
              <Route
                path={ROUTES.cms.categories.add.generate()}
                element={<AddCategoryScreen />}
              />
              {/*Pages*/}
              <Route
                path={ROUTES.cms.pages.add.generate()}
                element={<AddPageScreen />}
              />
              <Route
                path={ROUTES.cms.pages.details.generate(":pageId")}
                element={<PageScreen />}
              />
              <Route
                path={ROUTES.cms.pages.list.generate()}
                element={<PagesScreen />}
              />
              {/*Structure*/}
              <Route
                path={ROUTES.cms.structure.details.generate()}
                element={<StructureScreen />}
              />
              {/*Keywords*/}
              <Route
                path={ROUTES.cms.keywords.details.generate()}
                element={<KeywordsScreen />}
              />
              :leadId
            </Route>
          </Route>
        </Routes>
      </MyContextProvider>
    </div>
  );
};

export default App;
