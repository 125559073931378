import { useEffect, useState, FunctionComponent } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Field } from "@type/form/field.types";
import {
  ContactResponseDto,
  IndividualContactResponseDto,
} from "@state/contact/dto/contact.response.dto";
import {
  requestGetContact,
  requestGetIndividualContact,
} from "@state/contact/ContactEffets";
import { formUtils } from "@utils/form-utils";
import { formItemBlockLayout } from "@utils/Constant";
import { ArrowLeftOutlined } from "@ant-design/icons";
import InputFormField from "@components/inputs/InputFormField";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import { toastError } from "@utils/toast-helper";

export interface Props {
  contactId?: string;
  particulier?: boolean;
}

const mapContactFields = (contact: ContactResponseDto): Field[] => {
  return formUtils.mapFieldsFrom(contact);
};

const mapIndividualContactFields = (
  contact: IndividualContactResponseDto,
): Field[] => {
  return formUtils.mapFieldsFrom(contact);
};

const ContactForm: FunctionComponent<Props> = ({ contactId, particulier }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [fields, setFields] = useState<Field[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (contactId) {
      particulier
        ? void requestGetIndividualContact(contactId)
        : void requestGetContact(contactId);
    }
  }, [contactId, particulier]);

  useEffect(() => {
    return requestGetContact.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setFields(mapContactFields(result.data));
      } else {
        toastError(t<string>("contact.notFound"));
      }
    });
  });

  useEffect(() => {
    return requestGetIndividualContact.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setFields(mapIndividualContactFields(result.data));
      } else {
        toastError(t<string>("contact.notFound"));
      }
    });
  });

  return (
    <Form {...formItemBlockLayout} form={form} fields={fields}>
      <div className="form-first-item-sticky-top-container">
        <div className="form-first-item-sticky-top-content">
          <div className="d-flex align-items-center justify-content-between flex-wrap px-3">
            <div className="d-flex align-items-center justify-content-center flex-wrap">
              <Button
                htmlType="reset"
                className="m-2"
                onClick={() => navigate(-1)}
              >
                <ArrowLeftOutlined /> {t<string>("buttons.back")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Divider orientation="left">
        <h3 className="text-secondary mb-0">
          {t<string>("contacts.contact.details.infoContact")}
        </h3>
      </Divider>
      <Row>
        <Col xs={24} sm={12} lg={24} xl={12}>
          <InputFormField
            className="mx-2"
            showLabel
            module="contacts.contact"
            field="firstName"
            readOnly={true}
          />
        </Col>
        <Col xs={24} sm={12} lg={24} xl={12}>
          <InputFormField
            className="mx-2"
            showLabel
            module="contacts.contact"
            field="lastName"
            readOnly={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12} lg={24} xl={12}>
          <InputFormField
            className="mx-2"
            showLabel
            module="contacts.contact"
            field="email"
            readOnly={true}
          />
        </Col>
        <Col xs={24} sm={12} lg={24} xl={12}>
          <InputFormField
            className="mx-2"
            showLabel
            module="contacts.contact"
            field="phone"
            readOnly={true}
          />
        </Col>
      </Row>
      <Row>
        {!particulier && (
          <Col xs={24} sm={12} lg={24} xl={12}>
            <InputFormField
              className="mx-2"
              showLabel
              module="contacts.contact"
              field="siret"
              readOnly={true}
            />
          </Col>
        )}
      </Row>
      {particulier && (
        <>
          <Row>
            <Col xs={24} sm={12} lg={24} xl={12}>
              <InputFormField
                className="mx-2"
                showLabel
                module="contacts.contact"
                field="companyName"
                readOnly={true}
              />
            </Col>
            <Col xs={24} sm={12} lg={24} xl={12}>
              <InputFormField
                className="mx-2"
                showLabel
                module="contacts.contact"
                field="address"
                readOnly={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} lg={24} xl={12}>
              <InputFormField
                className="mx-2"
                showLabel
                module="contacts.contact"
                field="zipCode"
                readOnly={true}
              />
            </Col>
            <Col xs={24} sm={12} lg={24} xl={12}>
              <InputFormField
                className="mx-2"
                showLabel
                module="contacts.contact"
                field="city"
                readOnly={true}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={24} sm={12} lg={24} xl={12}>
          <InputFormField
            className="mx-2"
            showLabel
            module="contacts.contact"
            field="subject"
            readOnly={true}
          />
        </Col>
      </Row>
      <TextAreaFormField
        showLabel={true}
        module="contacts.contact"
        field="message"
        readOnly={true}
      />
    </Form>
  );
};
export default ContactForm;
