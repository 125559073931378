import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Divider, Empty, Form, Row, Table } from "antd";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import {
  requestGetContacts,
  requestGetContactsExport,
} from "@state/contact/ContactEffets";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import FileSaver from "file-saver";
import { requestGetUserInfo } from "@state/auth/AuthEffects";
import { SpinIcon } from "@components/icons/SpinIcon";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { ContactResponseDto } from "@state/contact/dto/contact.response.dto";
import { columns } from "@components/lists/ContactsColumns";
import {
  setFilterPage,
  setTablePageNumber,
  setTablePageSize,
} from "@state/auth/AuthEvents";
import InputFormField from "@components/inputs/InputFormField";
import DatePickerFormField from "@components/inputs/DatePickerFormField";
import moment, { Moment } from "moment";
import { SaveOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/es/table/interface";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@routes/Routes";

interface FilterObject {
  name: string;
  from: Moment;
  to: Moment;
}
const ContactsScreen: FC = () => {
  const { t } = useTranslation();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const authentifiedContext = useStore(authentifiedStore);
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "createdAt",
    order: "ascend",
  });

  const navigate = useNavigate();

  const contextFilter: FilterObject | undefined = authentifiedContext
    ?.dashboardsFilter?.["contacts"] as FilterObject | undefined;

  const [name, setName] = useState<string>(contextFilter?.name || "");
  const [from, setFrom] = useState<Moment>(
    contextFilter?.from || moment(firstDayOfMonth),
  );
  const [to, setTo] = useState<Moment>(contextFilter?.to || moment(today));

  const [pageNumber, setPageNumber] = useState<number>(
    authentifiedContext?.dashboardsPageNumber?.["contacts"] || 1,
  );
  const [pageSize, setPageSize] = useState<number>(
    authentifiedContext?.dashboardsPageSizes?.["contacts"] || 10,
  );

  const InitialValues = {
    query: name,
    from: from,
    to: to,
  };

  const onChangePageSize = (current: number, newPageSize: number): void => {
    setPageSize(newPageSize);
    setTablePageSize({
      value: newPageSize,
      property: "contacts",
    });
  };

  const onChangePageNumber = (current: number): void => {
    setPageNumber(current);
    setTablePageNumber({
      value: current,
      property: "contacts",
    });
  };

  const onChangeName = (current: string): void => {
    setName(current);
    setFilterPage({
      value: {
        name: current,
        from: contextFilter?.from,
        to: contextFilter?.to,
      },
      property: "contacts",
    });
    onChangePageNumber(1);
  };

  const onChangeDateFrom = (current: Moment): void => {
    setFrom(current);
    setFilterPage({
      value: {
        name: contextFilter?.name,
        from: current,
        to: contextFilter?.to,
      },
      property: "contacts",
    });
    onChangePageNumber(1);
  };

  const onChangeDateTo = (current: Moment): void => {
    setTo(current);
    setFilterPage({
      value: {
        name: contextFilter?.name,
        from: contextFilter?.from,
        to: current,
      },
      property: "contacts",
    });
    onChangePageNumber(1);
  };

  const [contacts, setContacts] =
    useState<Pageable<ContactResponseDto>>(emptySearchResult);

  useEffect(() => {
    void requestGetUserInfo({});
  }, []);

  const handleSubmit = () => {
    setButtonLoading(true);
    void requestGetContactsExport({
      dto: {
        from: from.toISOString().split("T")[0],
        to: to.toISOString().split("T")[0],
        query: name,
      },
    });
  };

  useEffect(() => {
    return requestGetContactsExport.done.watch(({ result }) => {
      afterWs(result);
    });
  });

  const afterWs = (result: ParsedResponse<Blob>) => {
    setButtonLoading(false);
    if (result.ok && result.data && result.fileName) {
      const data = new Blob([result.data], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(data, result.fileName);
      toastSuccess(t<string>("contacts.messages.generation.success"));
    } else {
      toastError(t<string>("contacts.messages.generation.error"));
    }
  };

  const fetchPages = useCallback(() => {
    setDataHasLoaded(false);
    requestGetContacts({
      page: pageNumber - 1,
      limit: pageSize,
      sorter,
      dto: {
        query: name,
        from: from ? from.toISOString().split("T")[0] : "",
        to: to ? to.toISOString().split("T")[0] : "",
      },
    })
      .catch(() => {
        toastError(t<string>("notification.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [from, name, pageNumber, pageSize, t, to, sorter]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    return requestGetContacts.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setContacts(result.data);
      }
    });
  });

  return (
    <PageLayout title={t<string>("contacts.title")}>
      <>
        <Divider orientation="left">
          <h3 className="text-secondary mb-0">{t<string>("page.filter")}</h3>
        </Divider>
        <Form initialValues={InitialValues}>
          <Row>
            <Col xs={24} md={12}>
              <InputFormField
                showLabel
                module="contacts.contact.filters"
                field="query"
                onPressEnter={(e) => {
                  e.preventDefault();
                  onChangeName(e.currentTarget.value);
                }}
              />
            </Col>
            <Col>
              <DatePickerFormField
                showLabel
                module="contacts"
                field="from"
                mode="date"
                picker="date"
                onChange={(value) => onChangeDateFrom(value as Moment)}
              />
            </Col>
            <Col>
              <DatePickerFormField
                showLabel
                module="contacts"
                field="to"
                mode="date"
                picker="date"
                onChange={(value) => onChangeDateTo(value as Moment)}
                disabledDate={(current) => {
                  if (current > moment()) {
                    return true;
                  }
                  return from && current < from;
                }}
              />
            </Col>
          </Row>
        </Form>
        <div className="d-flex flex-column align-items-stretch flex-sm-row">
          <div className="w-100 d-flex align-items-center">
            <Divider orientation="left" className="mb-0 mb-sm-3 mt-3">
              <h3 className="text-secondary mb-0">
                {t<string>("contacts.list_title")}
              </h3>
            </Divider>
          </div>
          <div className="w-100 d-flex align-items-center divider-sm-transparent">
            <Divider orientation="right" className="mt-2 mt-sm-3 mb-3">
              <Button
                className="btn-primary"
                onClick={handleSubmit}
                loading={buttonLoading}
              >
                <SaveOutlined /> {t<string>("common.download")}
              </Button>
            </Divider>
          </div>
        </div>
        <Table
          style={{ whiteSpace: "break-spaces" }}
          locale={{
            emptyText: <Empty description={t<string>("pages.table.noData")} />,
          }}
          loading={{ spinning: !dataHasLoaded, indicator: SpinIcon }}
          columns={columns(navigate)}
          dataSource={contacts.content}
          pagination={{
            total: contacts.totalElements,
            pageSize: contacts.size,
            current: contacts.number + 1,
            onChange: onChangePageNumber,
            onShowSizeChange: onChangePageSize,
            showSizeChanger: true,
          }}
          showSorterTooltip={false}
          rowKey={(record) => record.id}
          rowClassName="cursor-pointer"
          onChange={(pagination, filters, sorter) =>
            setSorter(sorter as SorterResult<any>)
          }
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(ROUTES.contacts.details.generate(record.id)),
            };
          }}
        />
      </>
    </PageLayout>
  );
};

export default ContactsScreen;
